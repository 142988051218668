<template>
  <v-app id="home">
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-breadcrumbs
              class="primary--text"
              :items="navigator"
              divider="/"
            ></v-breadcrumbs>
          </v-col>
          <v-col sm="7" style="text-align: left; padding-inline: 5%">
            <h1 class="primary--text top">Hello I'm Saurav Singh</h1>
            <h2 class="white--text">Web Developer</h2>
            <p class="grey--text">
              I'm very ambitious front-end developer worked in multiple IT
              companies.Love to work with the latest technologies on challenging
              and diverse projects. I'm quiet confident, naturally curious, and
              prepetually working on improving my skills.
              <br />
              <br />
              <span class="secondary--text">
                Family person / Sports Fanatic / Photography Enthusiast / Tech
                Obsessed
              </span>
            </p>
            <div class="d-flex">
              <v-btn tile color="primary" href="#contact" dark>
                contact me
              </v-btn>
              <v-card
                flat
                tile
                class="white--text d-flex justify-space-around ml-5 my-2 bannerIcon"
                width="25%"
                style="background: transparent"
              >
                <v-btn icon small class="social-icon" @click="btnClick('insta')"
                  ><v-icon>mdi-instagram</v-icon></v-btn
                >
                <v-btn
                  icon
                  small
                  class="social-icon"
                  @click="btnClick('linkedIn')"
                  ><v-icon>mdi-linkedin</v-icon></v-btn
                >
                <v-btn
                  icon
                  small
                  class="social-icon"
                  @click="btnClick('youtube')"
                  ><v-icon>mdi-youtube</v-icon></v-btn
                >
                <v-btn
                  icon
                  small
                  class="social-icon"
                  @click="btnClick('github')"
                  ><v-icon>mdi-github</v-icon></v-btn
                >
              </v-card>
            </div>
          </v-col>
          <v-col sm="5" class="pa-0">
            <div class="imgHolder">
              <v-img
                src="@/assets/banner-min.png"
                contain
                max-height="500"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="12" class="padd">
            <div class="first">
              <v-row>
                <v-col cols="12">
                  <div class="child bgColor1">
                    <v-icon color="primary" x-large class="ml-3"
                      >mdi-palette-swatch</v-icon
                    >
                    <h3 class="primary--text ml-3 mt-4">UI Design</h3>
                    <p class="grey--text ml-3 mt-6">
                      Understanding requirements, <br />develop and deploy the
                      UI
                    </p>
                    <v-btn color="primary" disabled dark text>
                      know more
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                  <div class="child primary">
                    <v-icon color="" x-large class="ml-3" dark
                      >mdi-shopping</v-icon
                    >
                    <h3 class="white--text ml-3 mt-4">Product Development</h3>
                    <p class="white--text ml-3 mt-6">
                      Understand & Develop product, <br />target audience,
                      features
                    </p>
                    <v-btn color="" dark disabled text>
                      know more
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                  <div class="child bgColor1">
                    <v-icon color="primary" x-large class="ml-3"
                      >mdi-book-open-page-variant</v-icon
                    >
                    <h3 class="primary--text ml-3 mt-4">Cloud & Automation</h3>
                    <p class="grey--text ml-3 mt-6">
                      Implement Cloud Services, <br />implement automation
                    </p>
                    <v-btn color="primary" dark disabled text>
                      know more
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="expBanner" id="about">
                  <div class="child1">
                    <h1 class="primary--text mt-4 number">4</h1>
                    <h3 class="white--text mt-4">Years Experience</h3>
                  </div>
                  <div class="child2 mRight">
                    <v-row>
                      <v-col cols="12" class="childcol">
                        <div class="child2 mButton padding bgColor1">
                          <h1 class="primary--text">20+</h1>
                          <p class="grey--text">Clients</p>
                        </div>
                      </v-col>
                      <v-col cols="12" class="childcol">
                        <div class="child2 padding bgColor1">
                          <h1 class="primary--text">62+</h1>
                          <p class="grey--text">Completed Projects</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="child2 windowPanelBtm">
                    <v-row>
                      <v-col cols="12" class="childcol">
                        <div class="child2 mButton padding bgColor1">
                          <h1 class="primary--text">04</h1>
                          <p class="grey--text">Years Experience</p>
                        </div>
                      </v-col>
                      <v-col cols="12" class="childcol">
                        <div class="child2 padding bgColor1">
                          <h1 class="primary--text">5</h1>
                          <p class="grey--text">Achievements</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col sm="4" offset-sm="2" id="project">
            <h4 class="primary--text">FEATURED PROJECTS</h4>
            <p class="grey--text">
              Some of the personal projects I have worked on
            </p>
          </v-col>
          <v-col sm="4" class="text-end">
            <v-btn tile disabled color="primary" dark> View All </v-btn>
          </v-col>
          <v-col sm="8" offset-sm="2">
            <v-card tile class="pa-5">
              <v-window v-model="onboarding" class="mb-2">
                <v-window-item v-for="project in projectList" :key="project.id">
                  <v-card height="max-content">
                    <div :class="$vuetify.breakpoint.mdAndDown ? '' : 'd-flex'">
                      <v-img
                        :width="$vuetify.breakpoint.mdAndDown ? '100%' : '50%'"
                        :src="require('@/assets/' + project.img)"
                      ></v-img>
                      <div>
                        <div v-if="$vuetify.breakpoint.mdAndDown">
                          <v-card-actions>
                            <v-btn color="primary" text>
                              {{ project.title }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="show = !show">
                              <v-icon>{{
                                show ? "mdi-chevron-up" : "mdi-chevron-down"
                              }}</v-icon>
                            </v-btn>
                          </v-card-actions>

                          <v-expand-transition>
                            <div v-show="show">
                              <v-divider></v-divider>

                              <v-card-text>
                                <span>Tech Stack:</span>
                                <v-chip-group column>
                                  <v-chip
                                    label
                                    small
                                    v-for="(skill, i) in project.techStack"
                                    :key="i"
                                    >{{ skill }}</v-chip
                                  >
                                </v-chip-group>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  outlined
                                  tile
                                  small
                                  @click="btnClick(project.demoLink)"
                                >
                                  Demo
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  outlined
                                  tile
                                  small
                                  @click="btnClick(project.sourceLink)"
                                >
                                  Source Code
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-expand-transition>
                        </div>
                        <div v-else>
                          <v-card-title class="primary--text">{{
                            project.title
                          }}</v-card-title>

                          <v-card-text>
                            <div>
                              {{ project.desc }}
                            </div>
                          </v-card-text>

                          <v-divider class="mx-4"></v-divider>

                          <v-card-title>Tech Stack used: </v-card-title>

                          <v-card-text>
                            <v-chip-group column>
                              <v-chip
                                label
                                v-for="(skill, i) in project.techStack"
                                :key="i"
                                >{{ skill }}</v-chip
                              >
                            </v-chip-group>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              outlined
                              tile
                              @click="btnClick(project.demoLink)"
                            >
                              Demo
                            </v-btn>
                            <v-btn
                              color="primary"
                              outlined
                              tile
                              @click="btnClick(project.sourceLink)"
                            >
                              Source Code
                            </v-btn>
                          </v-card-actions>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-window-item>
              </v-window>
              <v-divider></v-divider>
              <v-card-actions class="justify-space-between mt-3">
                <v-btn text @click="prev">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <template v-if="$vuetify.breakpoint.mdAndDown">
                  <v-item-group
                    v-model="onboarding"
                    class="text-center"
                    mandatory
                  >
                    <v-item
                      v-for="project in projectList"
                      :key="project.id"
                      v-slot="{ active, toggle }"
                    >
                      <v-btn
                        :input-value="active"
                        :color="active ? '#ff6700' : undefined"
                        icon
                        @click="toggle"
                      >
                        <v-icon>mdi-record</v-icon>
                      </v-btn>
                    </v-item>
                  </v-item-group>
                </template>
                <template v-else>
                  <v-slide-group v-model="onboarding" mandatory>
                    <v-slide-item
                      v-for="project in projectList"
                      :key="project.id"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        @click="toggle"
                        :color="active ? '#ff6700' : undefined"
                        class="ma-1 pa-1"
                        width="150"
                      >
                        <v-img
                          :src="require('@/assets/' + project.img)"
                        ></v-img>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </template>
                <v-btn text @click="next">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" class="padd topInverse contactContainer">
            <div class="second">
              <div class="secondchild1" id="contact">
                <v-row>
                  <v-col cols="12" md="6">
                    <h1 class="primary--text">
                      Let's work together on your next project
                    </h1>
                    <p class="white--text mt-2">
                      Interested in freelance opportunities - especially
                      ambitious or large projects.<br />
                      However, if you have other request or question, don't
                      hesitate to contact me.
                    </p>
                  </v-col>
                  <v-col cols="12" md="6">
                    <form @submit.prevent="sendEmail">
                      <v-row>
                        <v-col sm="6">
                          <input
                            type="text"
                            name="name"
                            v-model="name"
                            required
                            hidden
                          />
                          <v-text-field
                            v-model="name"
                            label="Name"
                            background-color="#111111"
                            required
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col sm="6">
                          <input
                            type="text"
                            name="email"
                            v-model="email"
                            required
                            hidden
                          />
                          <v-text-field
                            v-model="email"
                            label="E-mail"
                            background-color="#111111"
                            required
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-col sm="12" class="pa-0">
                        <input
                          type="text"
                          name="message"
                          v-model="message"
                          required
                          hidden
                        />
                        <v-textarea
                          outlined
                          v-model="message"
                          rows="5"
                          background-color="#111111"
                          placeholder="Enter your message..."
                        ></v-textarea>
                      </v-col>
                      <input
                        type="submit"
                        ref="formSubmit"
                        value="Send"
                        hidden
                      />
                    </form>
                    <v-btn
                      color="primary"
                      class="float-right"
                      @click="() => this.$refs.formSubmit.click()"
                      dark
                      >Contact</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import emailJs from "emailjs-com";
import { socialMediaBtn, scrollToTop } from "@/helpers/helperFunctions.js";
export default {
  data: () => ({
    drawer: false,
    group: null,
    name: "",
    email: "",
    message: "",
    onboarding: 0,
    show: false,
    navigator: [
      {
        text: "Main Page",
        disabled: false,
        href: "/",
      },
      {
        text: "Portfolio",
        disabled: true,
        href: "",
      },
    ],
    projectList: [
      {
        id: 0,
        title: "React JS Portfolio",
        desc: "This project include advance JavaScript concept like Email Templating ,3D modeling, SVG Animation, Text Animation, used animation library called Animate.css for basic animation in the project. The main purpose of this project was to explore the SVG animation.",
        img: "Reactportfolio.jpg",
        techStack: [
          "HTML",
          "CSS",
          "JavaScript",
          "ReactJS",
          "Postman",
          "Git",
          "Netlify",
        ],
        demoLink: "https://subtle-chebakia-f22a12.netlify.app/",
        sourceLink: "#",
      },
      {
        id: 1,
        title: "BootSaSS Portfolio",
        desc: "This project include advance JavaScript concept like Email Templating ,3D modeling, SVG Animation, Text Animation, used animation library called Animate.css for basic animation in the project. The main purpose of this project was to explore the SVG animation.",
        img: "BootSaSS.jpg",
        techStack: [
          "HTML",
          "CSS",
          "JavaScript",
          "ReactJS",
          "Postman",
          "Git",
          "Netlify",
        ],
        demoLink: "https://saurav0896.github.io/Boot-SASS-Portfolio/",
        sourceLink: "https://github.com/saurav0896/Boot-SASS-Portfolio",
      },
      {
        id: 3,
        title: "Twitter Clone",
        desc: "This project include advance JavaScript concept like Email Templating ,3D modeling, SVG Animation, Text Animation, used animation library called Animate.css for basic animation in the project. The main purpose of this project was to explore the SVG animation.",
        img: "Twitter.jpg",
        techStack: [
          "HTML",
          "CSS",
          "JavaScript",
          "VueJS",
          "Postman",
          "Git",
          "Netlify",
          "Vuetify",
          "FireBase",
          "Google Auth",
        ],
        demoLink: "#",
        sourceLink: "#",
      },
      {
        id: 4,
        title: "Durga Maa Art",
        desc: "This project include advance JavaScript concept like Email Templating ,3D modeling, SVG Animation, Text Animation, used animation library called Animate.css for basic animation in the project. The main purpose of this project was to explore the SVG animation.",
        img: "DurgaMaa.jpg",
        techStack: ["HTML", "CSS", "Git", "Code Pen"],
        demoLink: "https://codepen.io/saurav0896/full/PoebyvJ",
        sourceLink: "https://codepen.io/saurav0896/pen/PoebyvJ",
      },
      {
        id: 5,
        title: "Ganesh Visarjan Art",
        desc: "This project include advance JavaScript concept like Email Templating ,3D modeling, SVG Animation, Text Animation, used animation library called Animate.css for basic animation in the project. The main purpose of this project was to explore the SVG animation.",
        img: "GaneshJi.jpg",
        techStack: ["HTML", "CSS", "Git", "Code Pen"],
        demoLink: "https://codepen.io/saurav0896/full/ZEoEmog",
        sourceLink: "https://codepen.io/saurav0896/pen/ZEoEmog",
      },
    ],
  }),
  mounted() {
    scrollToTop();
    this.$store.state.title = "Portfolio";
    this.onboarding = this.projectList[0].id;
  },
  methods: {
    toggleDrawer() {
      document.documentElement.scrollTop = 0;
      this.drawer = !this.drawer;
    },
    scroll(refName) {
      const element = document.getElementById(refName);
      this.drawer = false;
      element.scrollIntoView({ behavior: "smooth" });
    },
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    btnClick: function (event) {
      socialMediaBtn(event);
    },

    sendEmail(e) {
      try {
        emailJs.sendForm(
          "service_w295tb5",
          "template_7bh4iub",
          e.target,
          "m1zsQMlneF84v8xU_",
          {
            name: this.name,
            email: this.email,
            message: this.message,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
  },
  metaInfo: {
    title: "SrvX - Portfolio | Blogs | Projects",
  },
};
</script>
<style scoped>
.top {
  margin-top: 180px;
}
.topInverse {
  margin-top: -200px;
}
.topTolbar {
  margin-top: 13%;
  text-align: center;
}

.imgHolder {
  background-color: rgba(255, 103, 0, 0.8);
  clip-path: polygon(35.5% 0%, 100% 0%, 64.9% 100%, 0% 100%);
  overflow: hidden;
  width: 90%;
  margin-inline: 3%;
}
.cursor-disable {
  cursor: not-allowed;
}
.social-icon:hover {
  color: #ff6700;
}
.first {
  width: 100%;
  height: 610px;
  background: linear-gradient(
    to right,
    #181818,
    #181818 50%,
    #111111 50%,
    #111111 50%
  );
  text-align: center;
  padding: 2rem 2rem;
}
.second {
  width: 100%;
  height: 450px;
  background: #181818;
  text-align: center;
  padding: 2rem 2rem;
}
.secondchild1 {
  display: inline-block;
  background-color: #1e1e1e;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-top: 250px;
}
.formControl {
  background: #111111;
  width: 100%;
}
.child {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-right: 8px;
}
.bgColor1 {
  background-color: #1e1e1e;
}
/* .bgColor2 {
  background-color: #ff6700;
} */

.expBanner {
  margin-top: 20px;
}

.child1 {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  margin-right: 5px;
  width: 240px;
}
.child2 {
  display: inline-block;
  width: 245px;
  vertical-align: middle;
}
.mRight {
  margin-right: 8px;
}
.mButton {
  margin-bottom: 8px;
}
.padding {
  padding: 8px 0;
}

.navBarDesign {
  padding-left: 8px;
  padding-right: 8px;
}

.col-12.padd {
  padding: 12px 0 !important;
}
.col-12.childcol {
  padding: 0 !important;
}
h1.number {
  font-size: 50px;
  font-weight: bold;
}

.contactContainer {
  height: 730px;
}

@media screen and (max-width: 1000px) {
  .top {
    margin-top: 10%;
  }
  .topTolbar {
    margin-top: 19%;
  }
}

@media screen and (max-width: 850px) {
  .first {
    height: max-content;
  }
  .child {
    margin-top: 1.6rem;
  }
  .windowPanelBtm {
    margin-top: 2rem;
    margin-left: -8px;
  }
  .contactContainer {
    height: 850px;
  }
  .topTolbar {
    margin-top: 38%;
  }
}

@media screen and (max-width: 650px) {
  .contactContainer {
    height: 940px;
  }
  .topTolbar {
    margin-top: 63%;
  }
}
@media screen and (max-width: 450px) {
  .second {
    margin-top: -30px;
  }
  .navBarDesign {
    padding: 0;
  }
  .bannerIcon {
    width: 60%;
  }
}
</style>
